<script>
// Import des composants et des utilitaires nécessaires
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import articleCard from "../../../components/widgets/card/articleCard.vue";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import loaderProcessVue from "../../../components/widgets/loaderProcess.vue";

export default {
  // Définition des métadonnées de la page
  page: {
    title: "Blog List",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    articleCard,
    loaderProcessVue,
  },
  data() {
    // Initialisation des données du composant
    return {
      titre: "", // Titre pour le processus en cours
      progress: 0, // Pourcentage de progression du chargement
      process: false, // Indicateur de processus de chargement
      searchQuery: "", // Chaîne de recherche pour filtrer les articles
      articles: [], // Tableau contenant les articles
      activeFilter: "all",
      filteredArticles: [],
      spinner: false,
      title: "Liste des articles", // Titre de la page
      items: [
        {
          text: "Blogs",
          // href: "/",
        },
        {
          text: "Articles",
          active: true,
        },
      ],
    };
  },
  methods: {
    filterArticles(filter) {
      if (filter === "all") {
        this.filteredArticles = this.articles;
      } else if (filter === "published") {
        this.filteredArticles = this.articles.filter(
          (article) => article.isPublished === true
        );
      } else if (filter === "unpublished") {
        this.filteredArticles = this.articles.filter(
          (article) => article.isPublished === false
        );
      } else if (filter === "PENDING") {
        this.filteredArticles = this.articles.filter(
          (article) => (article.status === 'PENDING' || article.status === 'PUBLISHED') && article.isPublished === false
        );
      }
      this.filter = filter; // Mettre à jour l'état du filtre actif

      // Mettre à jour le filtre actif
      this.activeFilter = filter;
    },
  },
  computed: {
    // Calcul de la liste des articles filtrés en fonction de la recherche
    filteredArticleList() {
      if (!this.searchQuery) {
        return this.filteredArticles;
      }
      return this.filteredArticles.filter((article) => {
        const fullName = article.titre;
        return fullName.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
  },
  mounted() {
    this.spinner = true;
    // Action exécutée après que le composant a été monté
    // this.process = true;   // Début du processus de chargement
    this.titre = "récupération des articles"; // Titre du processus
    const onUploadProgress = (progressEvent) => {
      this.progress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      ); // Mise à jour du pourcentage de progression
      console.log(`Upload progress: ${this.progress}%`);
    };
    // Appel à l'API pour récupérer la liste des articles
    Api.get("/actualite/rest/article/all-article", { onUploadProgress })
      .then((res) => {
        this.process = false; // Fin du processus de chargement
        this.articles = res.data.content; 
        this.spinner = false;
        this.filterArticles("all");
      })
      .catch((error) => {
        this.spinner = false; 
        Erreur.gestionErreur(error.message); // Gestion de l'erreur
      });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <loaderProcessVue
      :title="titre"
      :progress="progress"
      :visible="process"
    ></loaderProcessVue>

    <div class="row mb-3">
      <div class="col-lg-5">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            placeholder="Rechercher un article"
            style="border-radius: 8px"
            v-model="searchQuery"
          />
        </div>
      </div>
      <div class="col-lg-6"></div>
      <div class="col-lg-1">
        <router-link class="btn c2play-primary" to="/blog/createArticle">
          {{ $t("addButton") }}
        </router-link>
      </div>
    </div>

    <div>
      <button
        @click="filterArticles('all')"
        class="btn"
        :class="{ 'c2play-primary': activeFilter === 'all' }"
      >
        Tous
      </button>
      <button
        @click="filterArticles('published')"
        class="btn"
        :class="{ 'c2play-primary': activeFilter === 'published' }"
      >
        Publiés
      </button>
      <button
        @click="filterArticles('PENDING')"
        class="btn"
        :class="{ 'c2play-primary': activeFilter === 'PENDING' }"
      >
        En attente
      </button>
      <button
        @click="filterArticles('unpublished')"
        class="btn"
        :class="{ 'c2play-primary': activeFilter === 'unpublished' }"
      >
        En rédaction
      </button>
    </div>

    <div class="row"></div>
    <br />
    <div class="row">
      <div class="col-xl-12 col-lg-8">
        <div class="card">
          <div>
            <div class="row p-5">
              <div class="col-xl-12">
                <div
                  v-for="article in filteredArticleList"
                  :key="article.id"
                >
                  <articleCard :article="article"></articleCard>
                </div>
                <div
                  style="text-align: center; font-size: 3em"
                  v-if="spinner == true"
                >
                  <b-spinner variant="success" role="status"></b-spinner>
                </div>
                <div
                  style="text-align: center; font-size: 3em"
                  v-if="spinner == false && filteredArticleList.length == 0"
                >
                  <span style="font-size: 1.5rem"
                    >Aucun contenu disponoble !</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- Nav tabs -->
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
