<template>
  <div>
    <div class="row">
      <div class="col-lg-3">
        <router-link :to="`/blog/detail/${this.article.id}`">
          <img
            :src="article.imagesMap.imagepaysage.url"
            alt=""
            style="width: 100%; height: 200px; border-radius: 10px"
          />
        </router-link>
      </div>
      <div class="col-lg-9">
        <div class="row">
          <div class="col-lg-11">
            <h5>
              <router-link
                :to="`/blog/detail/${this.article.id}`"
                class="text-dark"
                >{{ article.titre }}
                <span
                  v-if="article.isPublished == true"
                  class="c2play-primary"
                  style="font-size: 0.7em; padding: 2px; border-radius: 10px"
                  >Publié <i class="mdi mdi-star"></i></span
              ></router-link>
            </h5>
          </div>
          <div class="col-lg-1">
            <b-dropdown
              class="card-drop"
              variant="white"
              menu-class="dropdown-menu-end"
              right
              toggle-class="p-0"
            >
              <template v-slot:button-content>
                <span
                  ><i class="mdi mdi-dots-horizontal font-size-18"></i
                ></span>
              </template>
              <b-dropdown-item :href="`/blog/detail/${this.article.id}`"
                >Détails</b-dropdown-item
              >
              <b-dropdown-item
                :href="`/blog/updateArticle/${this.article.id}`"
                >{{ $t("pages.videos.actions.modifier") }}</b-dropdown-item
              >
              <b-dropdown-item
                @click="confirm(article.id)"
                v-if="user.roles.includes('ROLE_ADMIN')"
                >{{ $t("pages.videos.actions.supprimer") }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <router-link :to="`/blog/detail/${this.article.id}`">
          <p class="text-muted">
            {{ getDateAndTimeFromDate(new Date(article.creationDate)) }}
          </p>

          <ul class="list-inline">
            <li class="list-inline-item me-3">
              <a href="javascript:void(0);" class="text-muted">
                <i
                  class="mdi mdi-chat-processing align-middle text-muted me-1"
                ></i>
                {{ article.comments.length }} Comment<span
                  v-if="article.comments.length > 1"
                  >s</span
                >
              </a>
            </li>
            <li class="list-inline-item me-3">
              <a href="javascript:void(0);" class="text-muted">
                <i class="mdi mdi-thumb-up align-middle text-muted me-1"></i>
                {{ article.like_users.length }} Like<span
                  v-if="article.like_users.length > 1"
                  >s</span
                >
              </a>
            </li>
            <li class="list-inline-item me-3">
              <a href="javascript:void(0);" class="text-muted">
                <i class="mdi mdi-eye align-middle text-muted me-1"></i>
                {{ article.nb_vue }} Vue<span v-if="article.nb_vue > 1">s</span>
              </a>
            </li>
          </ul>
          <p
            class="text-muted"
            style="
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            "
            v-html="article.content"
          ></p>
        </router-link>
      </div>
      <hr class="my-5" />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
export default {
  components: {},
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      articleList: {},
    };
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getDateAndTimeFromDate(date) {
      const monthsInFrench = [
        "janvier",
        "février",
        "mars",
        "avril",
        "mai",
        "juin",
        "juillet",
        "août",
        "septembre",
        "octobre",
        "novembre",
        "décembre",
      ];

      let year = date.getFullYear();
      let month = monthsInFrench[date.getMonth()];
      let day = date.getDate();

      return `${day} ${month} ${year}`;
    },

    confirm(articleId) {
      Swal.fire({
        title: "Voulez vous vraiment supprimer cet article?",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Non",
        confirmButtonText: "Oui",
      }).then((result) => {
        if (result.value) {
          // alert(id)
          this.deleteProgramme(articleId);
          Swal.fire(
            "Supprimé!",
            "Votre article a été suprimé avec succès.",
            "success"
          );
          // location.reload();
        }
      });
    },
    deleteProgramme(articleId) {
      Api.delete(`/actualite/rest/article/delete-article/${articleId}`)
        .then((response) => {
          this.channels = response.data.content;
          console.log(this.channels);
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
  },
  mounted() {},
};
</script>

<style></style>
